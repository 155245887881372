<template>
    <div v-if="asButton" class="auth-button" :class="buttonStyle" @click="$emit('click', {url})">
        <div class="icon" :class="icon">{{fullTooltip}}</div>
    </div>
    <div v-else class="auth-circle" :class="buttonStyle" @click="$emit('click', {url})">
        <div class="inner"><i class="icon" :class="icon">{{initials}}</i></div>
    </div>
</template>
<script>
export default {
    name: "OauthButton",
    tag: "oauth-button",
    emits: ["click"],
    props: {
        "url": { default: undefined },
        "method": { default: undefined },
        "name": { default: undefined },
        "initials": { default: undefined },
        "prefIcon": { default: 'thin-' },
        "legend": { default: undefined },
        "asButton": { type: Boolean, default: true },
        "outline": { type: Boolean, default: true }
    },
    computed: {
        buttonStyle() { return ((this.outline && this.asButton) ? 'outline ': '') + 'method-' + (this.method || 'default') },
        fullTooltip() { return this.$t(this.legend, {'oauth': this.capitalize(this.name || this.method)}) },
        tooltip() { return this.name || this.method },
        icon() { return this.prefIcon + this.method }
    },
    methods: {
        capitalize(str) {
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        }
    }
}
</script>