<template>
  <div class="vld-parent cyb-login">
    <login-form @sendSubmit="handleSubmit" formName="login" top-bar-action="register">
      <template #form>
        <Card class="p-fluid login-card">
          <template #header>
            <h1 class="active">{{ $t('login.login_title') }}</h1>
          </template>
          <template #content>
            <div class="p-fluid p-grid">
              <div class="p-col p-my-2">
                <h5 class="text-primary">{{$t("login.requestCredentials")}}</h5>
              </div>
            </div>
            <input
              type="text"
              id="username"
              name="username"
              v-model="email"
              autocomplete="username"
              class="hidden"
            />
            <div class="p-fluid p-grid">
              <div class="p-col">
                <div class="p-field">
                  <InputText
                    id="email"
                    v-model="email"
                    type="mail"
                    :placeholder="$t('common.email')"
                    autocomplete="email"
                  />
                  <field-error :value="v$.email" />
                </div>
              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-col">
                <div class="p-field">
                  <Password
                    id="password"
                    v-model="password"
                    toggleMask
                    :feedback="false"
                    :placeholder="$t('common.password')"
                    autocomplete="current-password"
                  />
                  <field-error :value="v$.password" />
                </div>
              </div>
            </div>
            <div class="p-fluid p-grid" :class="{ hidden: !accountVisible }">
              <div class="p-col">
                <div class="p-field">
                  <InputNumber id="accountid" v-model="accountId" mode="decimal" :min="1" :useGrouping="false" :placeholder="$t('common.account_id')" autocomplete="account-id"/>
                  <field-error :value="v$.accountId" />
                </div>
              </div>
            </div>
            <div class="p-fluid p-grid">
                <div class="p-col text-right small">
                  <router-link to="/forgotPassword">{{$t('login.forgot_your_password')}}</router-link>
                </div>
            </div>
            <div class="p-fluid p-grid p-mb-2">
              <div class="p-col">
                <div class="p-field-checkbox">
                  <Checkbox id="chkRememberMe" v-model="rememberMe" :binary="true"></Checkbox
                  ><label for="chkRememberMe">{{ $t('login.rememberMe') }}</label>
                </div>
              </div>
            </div>
          </template>
          <template #footer>
            <Button
              type="submit"
              :label="$t('login.login_button')"
              :disabled="loginState.locked || inProcess"
            />
            <oauth-section legend="login.signInWith" />
            <field-error
              class="form-errmsg p-mb-1 p-mt-1 text-center"
              id="msgerror"
              :value="errorMessage"
            />
            <!-- <div class="p-mt-3 text-center small">{{ $t('login.dont_have_an_account') }} <router-link to="./Register" tag="a">{{ $t('register.title') }}</router-link></div> -->
          </template>
        </Card>
      </template>
    </login-form>
  </div>
</template>

<script>
import { authService } from '../services/auth.service';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import { ErrorHelper } from '../helpers/ErrorHelper';
import LoginForm from '../components/LoginForm.vue';
import FieldError from '../components/FieldError.vue';
import OauthSection from '../components/OauthSection.vue';

let defaultUserLogin = {
  rememberMe: false,
  accountVisible: false,
  accountId: '',
  email: '',
};

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      errorMessage: '',
      password: '',
      inProcess: false,
      loginState: {
        count: 0,
        seconds: 0,
        locked: false,
      },
      userLogin: JSON.parse(JSON.stringify(defaultUserLogin)),
      loginError: false,
      savedData: null,
    };
  },
  computed: {
    accountId: {
      get: function() {
        if (this.userLogin.accountId === '') {
          return null; // Para que no salga un 0
        } else {
          return this.userLogin.accountId;
        }
      },
      set: function(value) {
        this.userLogin.accountId = value;
      },
    },
    email: {
      get: function() {
        return this.userLogin.email;
      },
      set: function(value) {
        this.userLogin.email = value;
      },
    },
    accountVisible: {
      get: function() {
        return this.userLogin.accountVisible;
      },
      set: function(value) {
        this.userLogin.accountVisible = value;
      },
    },
    rememberMe: {
      get: function() {
        return this.userLogin.rememberMe;
      },
      set: function(checked) {
        this.userLogin.rememberMe = checked;
      },
    },
  },
  validations() {
    return {
      email: { required, email },
      password: { required },
    };
  },
  components: {
    LoginForm,
    FieldError,
    OauthSection
  },
  mounted() {
    let ls = JSON.parse(localStorage.getItem('thinfinity.workspaces.loginState'));
    if (ls === null) {
      (this.loginState.locked = false), (this.loginState.count = 0), (this.loginState.seconds = 60);
    } else {
      (this.loginState.locked = ls.locked),
        (this.loginState.count = ls.count),
        (this.loginState.seconds = ls.seconds);
    }
    if (this.loginState.locked && this.loginState.seconds <= 0) this.loginState.seconds = 60;
    if (this.loginState.locked) this.initCountdown();
    ls = localStorage.getItem('thinfinity.workspaces.userLogin');
    if (ls != null) {
      let usrl = JSON.parse(Buffer.from(ls, 'base64'));
      if (typeof usrl.accountVisible == "undefined") usrl.accountId = '';
      this.userLogin = usrl;
      this.savedData = { ...usrl };
    }

    this.parseQueryErrorData();
  },
  methods: {
    unLocked() {
      this.loginState.locked = false;
      this.loginState.seconds = 60;
      this.loginState.count = 0;
      this.saveStorage();
      this.errorMessage = '';
    },

    saveStorage() {
      localStorage.setItem('thinfinity.workspaces.loginState', JSON.stringify(this.loginState));
      localStorage.setItem(
        'thinfinity.workspaces.userLogin',
        Buffer.from(JSON.stringify(this.rememberMe ? this.userLogin : defaultUserLogin)).toString(
          'base64'
        )
      );
    },
    initCountdown() {
      var countdown = setInterval(
        function(_this) {
          _this.loginState.seconds--;
          _this.errorMessage = _this.$t('login.severalErrorsMessage', { sec: _this.loginState.seconds });
          _this.saveStorage();
          if (_this.loginState.seconds <= 0) {
            clearInterval(countdown);
            _this.unLocked();
          }
        },
        1000,
        this
      );
    },
    loginLocked() {
      this.loginState.count += 1;
      this.saveStorage();
      if (this.loginState.count > 2) {
        this.loginState.locked = true;
        this.saveStorage();
        this.initCountdown();
        return true;
      }
      return false;
    },
    // checkSubmit(e) {
    //   if (e.key === 'Enter' || e.keyCode === 13) {
    //     this.handleSubmit();
    //   }

    //   return
    // },
    handleSubmit() {
      this.errorMessage = '';
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.loginLocked();
        return;
      }
      this.inProcess = true;
      this.$store.commit('setNotificationsOnLogin', true);
      authService
        .login(this.accountId, this.email, this.password)
        .then(() => {
          this.inProcess = false;
          this.loginError = false;
          this.accountVisible = !!this.accountId;
          this.unLocked();
        })
        .catch((error) => {
          this.handleLoginError(error);
        });
    },
    handleLoginError(error) {
      let ec = ErrorHelper.getErrorCode(error);
      if (ec == ErrorHelper.RESPCODE_INVALIDACCOUNT) {
        this.loginError = true;
        this.accountVisible = true;
        this.errorMessage = this.$t("login.accountRequired");
      } else {
        this.errorMessage = ErrorHelper.getErrorMessage(error);
      }
      this.inProcess = false;
      return;
    },
    parseQueryErrorData() {
      let { errmsg, ...query } = this.$route.query;
      errmsg = errmsg ? errmsg.replaceAll(' ', '_') : errmsg;

      let errorData;
      try {
        errorData = errmsg ? JSON.parse(Buffer.from(errmsg, 'base64')) : null;
      } catch(err) { /**/ }

      if (errorData) {
        this.handleLoginError(errorData);
      }

      //clear used query params
      this.$router.replace({ query: { ...query } });
    },
  },
  watch: {
    email(val) {
      if (val !== this.savedData?.email) {
        if (this.accountVisible) {
          this.userLogin.accountId = "";
          this.accountVisible = false;
        }
      } else {
        this.userLogin = { ...this.savedData };
      }
    }
  }
};
</script>