<template>
    <div v-if="methods.length > 0">
        <div class="hseparator"><hr><span>{{separatorMessage}}</span></div>
        <div v-if="longButtons">
            <oauth-button v-for="(item, index) in methods" :key="index" :asButton="true" :method="item.id" :outline="outline" :name="item.name" :legend="legend" :url="oauthUrl(item.id)" @click="process"/>
        </div>
        <div v-else class="auth-list">
            <div class="auth-list-row">
                <oauth-button v-for="(item, index) in methods" :key="index" :asButton="false" :method="item.id" :name="item.name" :legend="legend" :url="oauthUrl(item.id)" @click="process"/>
            </div>
        </div>
    </div>
</template>
<script>

import OauthButton from '../components/OauthButton.vue';
import { apiConfig } from '../config/backend-api';

const LIMIT_FOR_LONGBUTTONS = 2;

export default {
    name: "OauthSection",
    tag: "oauth-section",
    components: { OauthButton },
    emits: ["click"],
    props: {
        "baseurl": { default: null },
        "useButtons": { type: Boolean, default: true },
        "outline": { type: Boolean, default: false },
        "prefIcon": { default: 'thin-' },
        "action": { default: 'start' },
        "legend": { default: undefined }
    },
    computed: {
        baseUrl() {
            return this.baseurl || this.baseUrlDefault
        },
        baseUrlDefault() {
            let ori = new URL(apiConfig.baseUrl);
            return ori.origin + "/oauth2";
        },
        methods() {
            const oauthEnabledMethods = this.action == 'start' ? process.env.VUE_APP_OAUTH_LOGIN_ENABLED_METHODS : process.env.VUE_APP_OAUTH_REGISTER_ENABLED_METHODS;
            return (apiConfig.oauthProviders || []).filter(t => oauthEnabledMethods.includes(t.id));
        },
        longButtons() {
            return this.useButtons && (this.methods?.length || 0) <= LIMIT_FOR_LONGBUTTONS;
        },
        separatorMessage() {
            return (this.longButtons) ? this.$t("login.or") : (this.action == 'start') ? this.$t("login.orSignInWith") : this.$t("login.orRegisterWith");
        }
    },
    methods: {
        process({url}) {
            location.href = url;
        },
        oauthUrl(method) {
            return [this.baseUrl, method, this.action].join('/');
        }
    }
}
</script>