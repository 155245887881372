<template>
<div class="p-d-flex p-flex-column layers-container" :class="layersContainerClass">
  <div class="layer-topbar landing-home">
    <div class="header-back">
      <div class="landing-header p-mt-0 p-grid">
        <div class="p-col-6 p-md-3">
          <slot name="left">
            <img class="logo p-cursor-pointer" @click="this.$router.push('/')" src="/images/thinfinity-white.svg" />
          </slot>
        </div>
        <div class="p-col-0 p-md-7">
        </div>
        <div class="p-col-6 p-md-2 cta">
          <router-link v-if="topBarAction == 'login'" to="/login" class="p-button-login">
            {{$t("loginform.topbar.loginButton")}}
          </router-link>
          <router-link v-if="topBarAction == 'register'" to="/register" class="p-button-login">
            {{$t("loginform.topbar.registerButton")}}
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="layer2 p-d-flex p-flex-column p-ai-center p-jc-center" :class="pageclass">
    <slot name="text-content">
      <div class="p-d-flex p-flex-column p-ai-center p-jc-center cyb-login-container">
        <!-- <div class="cyb-login-misc p-mb-3 p-mt-3">
          <h2 class="text-thin">{{$t('loginform.productName')}}</h2>
        </div> -->
        <div class="cyb-login-form" v-if="hasForm">
          <div class="content-form">
            <form @submit.prevent="handleSubmit" :name="formName">
              <slot name="form"></slot>
            </form>
          </div>
        </div>
      </div>
    </slot>
  </div>
  <div class="cyb-login-footer p-d-flex p-ai-center p-jc-center">
    <slot name="footer">
      <landing-footer/>
    </slot>
  </div>
  <beta-version/>
</div>
</template>
<script>
import BetaVersion from './BetaVersion.vue';
import LandingFooter from './landing/LandingFooter.vue';

export default {
  components: { BetaVersion, LandingFooter },
  props: {
    "hasForm": {default: true },
    "pageclass": { default: "" },
    "formName": { default: "loginform" },
    "topBarAction": { default: "login" },
    "layersContainerClass": { default: "" }
  },
  emits: ["sendSubmit"],
  data() {
      return {
          "isBeta": process.env.VUE_APP_BETA_VERSION == 'TRUE'
      }
  },
  computed: {
    itemList: function() {
      let items = [];
      let i = 0;
      let key = "login.welcomeFeatures["+i+"]";
      let value = this.$t(key);
      while (key != value) {
        items.push(value);
        i++;
        key = "login.welcomeFeatures["+i+"]";
        value = this.$t(key);
      }
      return items;
    }
  },
  methods: {
    handleSubmit() {
      this.$emit("sendSubmit");
    }
  }
}
</script>